.products {
  background-color: #17171A;
  padding-bottom: 64px;
}
.products__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 24px;
}
.products__list .product-card {
  margin-top: 24px;
}
.products__list .product-card:first-child {
  margin-top: 0;
}

@media screen and (min-width: 769px) {
  .products {
    padding-bottom: 96px;
  }
  .products__title {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 40px;
  }
  .products__list {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: -24px;
    margin-left: -24px;
  }
  .products__list .product-card {
    width: calc(50% - 24px);
    margin-left: 24px;
  }
  .products__list .product-card:first-child {
    margin-top: 24px;
  }
}
@media screen and (min-width: 993px) {
  .products__title {
    font-size: 40px;
    line-height: 48px;
  }
  .products__list {
    margin-top: -30px;
    margin-left: -30px;
  }
  .products__list .product-card {
    width: calc(33.3% - 30px);
    margin-left: 30px;
  }
  .products__list .product-card,
  .products__list .product-card:first-child {
    margin-top: 30px;
  }
}