.burger {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 22px;
  height: 16px;
}
.burger__line {
  position: relative;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: #934F94;
  border-radius: 5px;
  margin-top: 5px;
  transform: rotate(0);
  opacity: 1;
  transition: all ease .3s;
}
.burger__line:nth-child(1) {
  width: 70%;
  margin-top: 0;
}
.burger__line:nth-child(3) {
  width: 70%;
}
.burger_active .burger__line:nth-child(1) {
  width: 100%;
  transform: rotate(45deg);
  top: 7px;
}
.burger_active .burger__line:nth-child(2) {
  transform: rotate(90deg);
  opacity: 0;
}
.burger_active .burger__line:nth-child(3) {
  width: 100%;
  transform: rotate(-45deg);
  top: -7px;
}

@media screen and (min-width: 993px) {
  .burger {
    display: none;
  }
}