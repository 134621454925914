* {box-sizing: border-box;}
html {
  font-size: 16px;
}
body, html {
  width: 100%;
  height: 100%;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  line-height: 1.42;
  -webkit-font-smoothing: antialiased;
  background-color: #17171A;
}
input, textarea {
  -webkit-appearance: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
input:focus, textarea:focus {
  outline: 0;
}
ol, ul {
  padding: 0;
  margin: 0;
}
p {margin: 0; padding: 0;}
table {border-collapse: separate;border-spacing: 0;}
caption, th, td {text-align: left;font-weight: normal;}
blockquote:before, blockquote:after,
q:before, q:after {content: "";}
blockquote, q {quotes: "" "";}
img {max-width: 100%;}
body.scroll-locked {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
body.padding-top {
  padding-top: 60px;
}
.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 16px;
}
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  margin: 0;
  font-weight: inherit;
}
.button {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  background-color: #934F94;
  border: none;
  border-radius: 8px;
  padding: 14px 24px;
  cursor: pointer;
  transition: background-color ease .3s;
}
.button img {
  display: inline-block;
  vertical-align: middle;
}
.button:hover {
  background-color: #a75da8;
}
.button:disabled {
  pointer-events: none;
}

@media screen and (min-width: 993px) {
  body.padding-top {
    padding-top: 80px;
  }
  .button {
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
}