.form__custom-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #17171A;
  border: 1px solid #656B7E;
  border-radius: 8px;
}
.form__custom-field input {
  border-color: transparent;
  border-radius: 0;
}
.form__input {
  flex-grow: 1;
  min-width: 100px;
  height: 48px;
  font-size: 16px;
  line-height: 20px;
  color: #A2A7B9;
  background-color: transparent;
  border: 1px solid #656B7E;
  border-radius: 8px;
  outline: 0;
  padding: 0 11px 0 16px;
}
.form__help-text {
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  color: #ffffff;
  margin-top: 12px;
}
.form__button {
  margin-top: 20px;
}
.form__textarea {
  display: block;
  width: 100%;
  height: 160px;
  font-size: 14px;
  line-height: 16px;
  color: #A2A7B9;
  background-color: #17171A;
  border: 1px solid #656B7E;
  border-radius: 8px;
  padding: 16px;
  resize: none;
  opacity: 1;
}
.form__textarea::placeholder,
.form__input::placeholder {
  color: #A2A7B9;
}
.form_error .form__custom-field {
  border-color: #FFA500;
}
.form_error .form__custom-field input {
  border-right-color: #FFA500;
}
.form_error input {
  color: #FFA500;
}

@media screen and (min-width: 993px) {
  .form__custom-field {
    flex-grow: 1;
    max-width: 368px;
  }
  .form__textarea,
  .form__input {
    font-size: 16px;
    line-height: 20px;
    opacity: 1;
  }
}