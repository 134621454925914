.advantage {
  background-color: #17171A;
  padding: 64px 0;
}
.advantage__picture {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: -65px;
}
.advantage__title {
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
}
.advantage__description {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 24px;
  opacity: 0.8;
}
.advantage__link {
  margin-top: 24px;
}
.advantage__link img {
  margin-left: 4px;
  margin-top: -3px;
}
.advantage__list {
  font-size: 14px;
  line-height: 32px;
  color: #868A99;
  padding-left: 20px;
  margin-top: 16px;
}
.advantage__mobile-apps {
  margin-top: 16px;
}
.advantage_second,
.advantage_four {
  background-color: #131315;
}
.advantage_second .advantage__title {
  max-width: 80%;
}

@media screen and (min-width: 769px) {
  .advantage {
    padding-top: 86px;
    padding-bottom: 86px;
  }
  .advantage__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .advantage__picture {
    width: 46%;
    margin-bottom: 0;
  }
  .advantage__content {
    width: 54%;
    padding-top: 0;
  }
  .advantage_reverse .advantage__picture {
    width: 46%;
    order: 2;
  }
  .advantage_reverse .advantage__content {
    width: 54%;
    order: 1;
  }
  .advantage__links {
    margin-top: 24px;
  }
  .advantage__list {
    margin-top: 24px;
  }
  .advantage__mobile-apps {
    margin-top: 24px;
  }
  .advantage_second .advantage__title {
    max-width: 100%;
  }
}

@media screen and (min-width: 993px) {
  .advantage__title {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 12px;
  }
  .advantage__description {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 24px;
    margin-top: 12px;
  }
  .advantage__links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .advantage__link {
    margin-top: 0;
  }
  .advantage__link:first-child {
    margin-left: 0;
  }
  .advantage__link-mobile {
    display: none;
  }
  .advantage__link-desktop {
    display: block;
  }
  .advantage_second .advantage__description {
    max-width: 550px;
  }
}