.header {
  position: relative;
  z-index: 12;
  background-color: #17171A;
  transition: top ease .5s;
}
.header_scrolled {
  position: fixed;
  top: -150px;
  left: 0;
  z-index: 12;
  width: 100%;
}
.header_show {
  top: 0;
}
.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.header_scrolled .header__content {
  height: 40px;
}
.header_scrolled .logo__img {
  width: 32px;
  height: 32px;
}

@media screen and (min-width: 993px) {
  .header__content {
    height: 80px;
  }
  .header_scrolled .header__content {
    height: 50px;
  }
  .header_scrolled .logo__img {
    width: 40px;
    height: 40px;
  }
  .header_scrolled .menu__button {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}